@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-initialized .slick-slide {
  display: block !important;
  width: 111px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body,
html {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
::selection {
  color: #ffffff;
  background: #000000;
}
::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}
:-moz-placeholder {
  color: #fff;
  opacity: 1;
}
::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

a,
a span,
button {
  transition: all 0.2s ease-in-out;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
}

.SalaEscolhida span .SalaNome,
.SalaEscolhida span .SalaTexto1 {
  color: #0dd6b2 !important;
}

.SalaEscolhidaEscolhida span .SalaNome,
.SalaEscolhidaEscolhida span .SalaTexto1 {
  color: #0dd6b2 !important;
  -webkit-user-select: all;
      -ms-user-select: all;
          user-select: all;
}
.SalaEscolhidaEscolhida span .SalaTexto2 {
  color: #e0e0e0 !important;
  -webkit-user-select: all;
      -ms-user-select: all;
          user-select: all;
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

.AlertaJs {
  text-align: center;
  width: calc(90% - 40px);
  max-width: calc(500px - 40px);
  min-width: calc(320px - 40px);
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #000000;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
}

.AlertaJs > button {
  width: 120px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  outline: none;
  background: #000;
  border-radius: 5px;
}

.AlertaJs > button:hover {
  background: #000;
}

.AlertaJs h1 {
  margin: 0 0 10px;
}

.react-confirm-alert-overlay {
  background: rgb(0 0 0 / 0.7) !important;
  -webkit-animation: react-confirm-alert-fadeIn 0.3s 0.2s forwards !important;
          animation: react-confirm-alert-fadeIn 0.3s 0.2s forwards !important;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.custom-ui {
  text-align: center;
  width: calc(90% - 80px);
  max-width: calc(540px - 20px);
  min-width: calc(320px - 20px);
  margin: 0 auto;
  padding: 20px;
  background: #971d76;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.custom-ui > button {
  width: 120px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px;
  outline: none;
  background: #971d76;
}

.custom-ui > button:hover {
  background: #971d76;
}

.custom-ui h1 {
  margin: 0 0 10px;
}

.MenuMob a {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 700;
  display: block;
  margin-bottom: 15px;
  cursor: pointer;
  color: #ffffff;
}
.MenuMob a:hover {
  color: #f8b03c;
}

#menuToggle {
  display: block;
  position: fixed;
  top: 35px;
  right: 35px;
  z-index: 10;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  cursor: pointer;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  -webkit-transform-origin: 4px 0;

          transform-origin: 4px 0;

  transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
    -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
    -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menuToggle span:first-child {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}

#menuToggle span:nth-last-child(2) {
  -webkit-transform-origin: 0 100%;
          transform-origin: 0 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span {
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2) {
  -webkit-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -50px;
  padding: 125px 50px 50px;

  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  -webkit-transform-origin: 0 0;

          transform-origin: 0 0;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);

  transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul {
  -webkit-transform: none;
          transform: none;
}

/* $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-color: black;
}

.embed-container iframe,
.embed-container object,
.embed-container embed,
.embed-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



@media (max-width: 1130px) {
  .slick-initialized .slick-slide {
    width: 95px !important;
  }
  
}




@media (max-width: 890px) {
  .LogoLive {
    margin: 0 auto;
    text-align: center;
  }
}

.TextoSessao {
  max-width: 970px;
  margin: 10px auto 0;
}


.CarrouselArtistas {
  width: 1660px;
  margin: 60px auto 0px;
  justify-content: space-between;
  display: flex;
  height: 170px;
}


.slick-next, .slick-next:focus, .slick-next:hover, .slick-prev, .slick-prev:focus, .slick-prev:hover {
  color: transparent !important;
  outline: transparent !important;
  background: transparent !important;
}

.slick-next:before, .slick-prev:before {
  font-family: "slick";
  font-size: 25px !important;
  line-height: 1;
  opacity: 1 !important;
  color: #000 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -35px !important;
  top: 51px !important;
}

.slick-next {
  right: -25px;
  top: 51px !important;
}



@media (max-width: 450px) {

.slick-prev {
    left: -25px!important;
    top: 45px!important;
}

.slick-next {
  right: -25px !important;
  top: 45px !important;
}

}




#c_verba {
  width: 100%;
  height: 65px;
  border: 2px solid #000000;
  margin-top: 20px;
  width: 750px;
  padding-left: 3%;
  width: 97%;
  font-family: 'Lato',sans-serif;
  font-size: 32px;
}

#c_verba::-webkit-input-placeholder {
  font-family: 'Lato', sans-serif;
  font-size: 25px;
  color: #000;
  font-weight: 800;
  padding-left: 20px;
}

#c_verba:-ms-input-placeholder {
  font-family: 'Lato', sans-serif;
  font-size: 25px;
  color: #000;
  font-weight: 800;
  padding-left: 20px;
}

#c_verba::placeholder {
  font-family: 'Lato', sans-serif;
  font-size: 25px;
  color: #000;
  font-weight: 800;
  padding-left: 20px;
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px) !important;
  margin-top: 25px !important;
}


@media (max-width: 1250px) {
  #c_verba {
      width: 97%;
  }
}


@media (max-width: 1100px) {
  #c_verba {    
      height: 75px;
      font-size: 20px;
      margin-top: 15px;
  }
}

@media (max-width: 490px) {
  #c_verba {
      height: 50px;
  }

  #c_verba::-webkit-input-placeholder {
      padding-left: 10px;
  }

  #c_verba:-ms-input-placeholder {
      padding-left: 10px;
  }

  #c_verba::placeholder {
      padding-left: 10px;
  }

}








@media (max-width: 1730px) {
  .CarrouselArtistas {
    width: 1110px;
  }
}


@media (max-width: 1130px) {
  .CarrouselArtistas {
    width: 758px;
  }
}

@media (max-width: 780px) {
  .CarrouselArtistas {
    width: 90%;
  }
}

@media (max-width: 500px) {
  .CarrouselArtistas {
    margin: 30px auto 0;
    width: 80%;
    height: 85px;
  }
}

.select {
    width: 75%;
    border: 0px;
    font-family: Lato, sans-serif;
    font-size: 17px;
    color: rgb(0, 0, 0);
    font-weight: bold;
}

.InputNovo {
    border: 0px;
    font-family: Lato, sans-serif;
    font-size: 24px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    width: 80%;
}

.InputNovo:focus, .InputNovo:focus, .InputNovo:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
} 
