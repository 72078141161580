.select {
    width: 75%;
    border: 0px;
    font-family: Lato, sans-serif;
    font-size: 17px;
    color: rgb(0, 0, 0);
    font-weight: bold;
}

.InputNovo {
    border: 0px;
    font-family: Lato, sans-serif;
    font-size: 24px;
    color: rgb(0, 0, 0);
    font-weight: bold;
    width: 80%;
}

.InputNovo:focus, .InputNovo:focus, .InputNovo:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
} 